@font-face {
    font-family: YahooSans;
    src: url('./fonts/YahooSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: YahooSans;
    src: url('./fonts/YahooSans-Semibold.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: YahooSans;
    src: url('./fonts/YahooSans-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: YahooSans;
    src: url('./fonts/YahooSans-ExtraBold.ttf') format('truetype');
    font-weight: bolder;
}

@font-face {
    font-family: YahooSans;
    src: url('./fonts/YahooSans-Light.ttf') format('truetype');
    font-weight: lighter;
}

body {
    background-color: #fff;
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
}

button {
    cursor: pointer;
}
